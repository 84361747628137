.menu-consultant {
  position: absolute;
  width: 15rem;
  top: 4rem;
  height: auto;
  background: var(--color-primary);
}

.menu-consultant .item {
  height: 2.5rem;
  padding: 0.5rem 1rem;
  width: 100%;
  color: var(--color-secundary);
  cursor: pointer;
}

.menu-consultant .item:hover {
  color: var(--color-third);
}
