.page-model-component {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--background-main);
  color: var(--text-color);
}

.page-model-component main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100%;
  min-height: 480px;

  padding: 0 15px 25px 15px;

  transition: width 300ms, opacity 400ms;
  opacity: 1;

  position: relative;

  overflow: auto;
}

.page-model-component main.transition {
  width: 0;
  opacity: 0;
  overflow: hidden;
}
