.login-page {
  width: 100vw;
  height: 100vh;
  background: #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-page form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 80%;
  min-height: 20rem;
  background: #fff;
  border-radius: 0.3rem;
  padding: 1.5rem 0 1.5rem 0;
}

.login-page form .logo {
  width: 200px;
  margin-bottom: 2rem;
}

.login-page form h2 {
  margin-bottom: 2rem;
}

@media (max-width: 1000px) {
  .login-page form {
    height: 100%;
  }

  .login-page form input,
  .login-page form button {
    height: 2.5rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 400px) {
  .login-page form {
    width: 100%;
    min-width: 13rem;
  }
}
