.admin-home-page {
  width: 100vw;
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
}

.admin-home-page .actions-header {
  display: flex;
  align-items: center;
}

.admin-home-page .actions-header button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: transparent;
  height: 3.5rem;
  color: #fff;
}

.admin-home-page .actions-header strong {
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
}

.admin-home-page .actions-header .logout-button {
  align-items: center;
  width: 3.5rem;
  border-radius: 50%;
  margin-left: 1.5rem;
}

.admin-home-page .actions-header .logout-button:hover {
  background: var(--color-primary-light);
  color: var(--color-secundary);
}

.admin-home-page main {
  display: flex;

  width: 100%;
  flex: 1;

  background-color: #fff;

  overflow: auto;

  padding: 1rem;
}

.admin-home-page main .grid-options {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;

  height: fit-content;
  width: 100%;

  background-color: #fff;
}

.admin-home-page main .grid-options button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 6rem;
  width: 100%;

  background: var(--color-primary-light);
  filter: opacity(0.9);
  color: #fff;
  border-radius: 0.3rem;
  font-size: 1.3rem;
  font-weight: bold;
}

.admin-home-page main .grid-options button:not(:disabled):hover {
  filter: opacity(1);
  background: linear-gradient(var(--color-primary), var(--color-primary-light));
  color: var(--color-secundary);
}

.admin-home-page main .grid-options button:disabled {
  background-color: #aaa;
  cursor: initial;
}

@media (max-width: 1000px) {
  .admin-home-page main .grid-options {
    grid-template-columns: 1fr 1fr;
  }

  .admin-home-page .actions-header .go-back-button {
    margin: 0;
  }
}

@media (max-width: 600px) {
  .admin-home-page main .grid-options {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
