.header-pages {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background: var(--color-primary);
  width: 100%;
  height: 5rem;
  color: #fff;
}

.header-pages .logo {
  height: 90%;
}

@media (max-width: 500px) {
  .header-pages {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 10rem;
  }

  .header-pages .logo {
    width: auto;
    height: auto;
  }
}
