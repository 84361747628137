.question-box {
  display: flex;
  width: 100%;
  flex: 1;
  padding: 0 2rem 0 2rem;
  flex-direction: column;
  align-items: center;
  overflow: auto;
}

.question-box .question-title {
  margin: 0.7rem 0;
}

.question-box .box-line-input {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1rem;
  margin: 0.3rem 0;
}

.question-box .input {
  width: 100%;
  height: 2rem;
  border-radius: 0.3rem;
  transition: 300ms;
}

.question-box .input-prescription.input-error,
.question-box .input.input-error {
  border: 1px solid var(--color-error);
}

@media (max-width: 1170px) {
  .change-question {
    width: 2rem;
    height: 2rem;
    font-size: 1rem;
  }
}

@media (max-width: 500px) {
  .question-box .box-line-input .box-input {
    flex-direction: column;
  }

  .box-line-input {
    flex-direction: column;
  }

  .box-line-input .box-select,
  .box-line-input .input-prescription,
  .box-line-input .input-frames {
    width: 100% !important;
  }
}
