.admin-consultant {
  width: 100vw;
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
}

.admin-consultant .actions-header {
  display: flex;
  align-items: center;
}

.admin-consultant .actions-header button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: transparent;
  height: 3.5rem;
  color: #fff;
}

.admin-consultant .actions-header strong {
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
}

.admin-consultant .actions-header .go-back-button {
  align-items: center;
  width: 3.5rem;
  border-radius: 50%;
  margin-left: 1.5rem;
}

.admin-consultant .actions-header .go-back-button:hover {
  background: var(--color-primary-light);
  color: var(--color-secundary);
}

.admin-consultant main {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  flex: 1;

  background-color: #fff;

  overflow: auto;

  padding: 1rem;

  position: relative;
}

.admin-consultant main form {
  flex: 1;
  max-width: 100%;

  border-radius: 3px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin-consultant main form h2 {
  margin-bottom: 3rem;
}

.admin-consultant main form .input-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0.5rem 0;
}

.admin-consultant main form .input-box select {
  width: 100%;
  height: 2.5rem;
  border-radius: 0.3rem;
  padding-left: 1rem;
  border: 0.05rem solid #999;
  background: #fff;
}

.admin-consultant main form .input-box select:focus {
  border: 0.1rem solid var(--color-primary);
}

.admin-consultant main form .input-box .input-box-email {
  display: flex;
  align-items: center;
}

.admin-consultant main form .input-box .input-box-email input {
  margin-right: 0.5rem;
}

@media (max-width: 1000px) {
  .admin-consultant main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: fit-content;
  }

  .admin-consultant .actions-header .go-back-button {
    margin: 0;
  }
}
