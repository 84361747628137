.page-not-found {
  width: 100vw;
  height: 100vh;
  background: #fff !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page-not-found h1 {
  margin-bottom: 2rem;
}

.page-not-found img {
  width: 300px;
  max-width: 90%;
}

.page-not-found .to-initial {
  text-decoration: none;
  color: #333;
  margin-top: 3rem;
}

.page-not-found .to-initial:hover {
  color: #000;
}
