.prescription-resume-content {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 10px;
}

.prescription-resume-content .problems {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.prescription-resume-content .problems .problem-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  flex: 1;
  max-width: 500px;

  padding: 10px;

  border: 5px;
}

.prescription-resume-content .problems .problem-item img {
  height: 185px;
  margin-bottom: 15px;
  border-radius: 3px;
}

.prescription-resume-content .problems .problem-item .description {
  font-size: 15px;
  opacity: 0.95;
  text-align: justify;
}
