* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100vw;
  height: 100vh;
  font-family: "Open Sans", sans-serif;
}

button {
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
}

input,
textarea,
select,
button {
  background: #ffffff66;
  font-family: "Open Sans", sans-serif;
}

html {
  --color-primary: #2d348c;
  --color-secundary: #fff;
  --color-third: #ffff20;
  --color-primary-light: #004a8a;
  --color-primary-opacity: #2d348c30;
  --background-modal: #2d348c90;
  --background-main: #fff;
  --text-color: #222;
  --button-text-color: #2d348c;
  --button-shadow-color: #00246e30;
  --color-error: #ff1f40;
  --color-success: #00a013;
}

.dark-theme {
  --background-main: #111;
  --text-color: #eee;
  --button-text-color: #eee;
  --button-shadow-color: #333;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-primary-opacity);
  border-radius: 8px;
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-primary-light);
}

::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
}
