.consultants .list {
  margin-bottom: 1rem;
  font-size: 14px;
  color: #000;

}

.consultants .list p {
  font-weight: 500;
  line-height: 1.5;
  font-size: 16px;
}
