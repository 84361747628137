.box-error-component {
  width: 100%;
  min-height: 40px;
  background-color: #aa0000;
  color: #fff;
  padding: 5px;

  border-radius: 3px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 1rem;
}
