.second-pair-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  width: 100%;
}

.second-pair-content .contact-lenses-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 100%;
  padding: 2rem;
}

.second-pair-content .recommendation {
  color: var(--color-primary);
  font-weight: bold;
}

.second-pair-content .options {
  display: flex;
  gap: 15px;

  flex: 1;

  overflow: auto;

  padding: 10px;
}

.second-pair-content .option {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  color: var(--color-primary);

  flex: 1;
  padding: 10px 20px;
  border-radius: 0.3rem;

  box-shadow: 0 0 10px var(--color-primary-opacity);

  transition: 200ms;

  cursor: pointer;
}

.second-pair-content .contact-img {
  height: 15rem;
  width: auto;
}

.second-pair-content .option:hover {
  opacity: 0.9;
}

.second-pair-content .option.selected {
  border-color: var(--color-primary);
  background-color: var(--color-primary-opacity);
}

.second-pair-content .option img {
  width: 10rem;
  max-width: 100%;
  border-radius: 8px;
}

.second-pair-content .option .description {
  text-align: justify;
  flex: 1;
  font-size: 14px;
  text-indent: 2ch;
  font-weight: normal;
  padding-right: 8px;
  color: inherit;
}

.second-pair-content .option .description .topics {
  list-style: none;
}

.second-pair-content .option .description .topics li {
  color: inherit;

  display: flex;
  align-items: center;
}

.second-pair-content .option .description .topics li::before {
  color: inherit;
  content: "• ";
  font-size: 30px;
  margin-right: 10px;
}

@media (max-width: 800px) {
  .second-pair-content .options {
    flex-direction: column;
  }
}

@media (max-width: 750px) {
  .second-pair-content .option {
    flex-direction: column;
    align-items: center;
  }

  .second-pair-content .option img {
    height: 15rem;
    width: auto;
  }

  .second-pair-content .option .description {
    width: 100%;
    margin-top: 1rem;
  }
}


