.loading-component {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fffffff0;
}

.loading-component .loading-circle {
  width: 100%;
  height: 100%;

  max-width: 70px;
  max-height: 70px;
  border-radius: 50%;
  border: solid var(--color-primary-light);
  border-top: solid var(--color-third);

  animation: spin 900ms infinite linear;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
