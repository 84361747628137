.modal-information-component {
  position: fixed;

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--background-modal);

  z-index: 10;
}

.modal-information-component .content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: 400px;
  max-width: 95%;

  gap: 20px;

  padding: 20px;

  border-radius: 8px;

  background-color: var(--background-main);
}

.modal-information-component .content strong {
  text-align: center;
}

.modal-information-component .content img {
  width: 50%;
}

.modal-information-component .content .exit-button {
  width: 200px;
  max-width: 100%;
  height: 35px;

  background-color: var(--color-primary);
  color: var(--color-secundary);

  border-radius: 3px;
}

.modal-information-component .content .exit-button:hover {
  color: var(--color-third);
}
