.modal-end-of-questionary-component {
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 20px;

  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;

  width: 100vw;
  height: 100vh;

  background-color: var(--color-primary-opacity);
}

.modal-end-of-questionary-component .modal-content {
  background-color: var(--background-main);

  width: 60%;
  max-width: 100%;
  height: 60%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-end-of-questionary-component h2 {
  margin-bottom: 20px;
}

.modal-end-of-questionary-component p {
  width: 600px;
  max-width: 100%;
  padding: 1rem;

  line-height: 25px;

  text-align: justify;
}

.modal-end-of-questionary-component .next-button {
  width: 150px;
  height: 40px;

  margin-top: 20px;

  background-color: var(--color-primary);
  color: var(--color-third);

  font-size: 15px;

  border-radius: 5px;
}

.modal-end-of-questionary-component .next-button:hover {
  opacity: 0.9;
}
