.recommendation-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
}

.recommendation-content .header-options {
  width: 100%;

  display: flex;

  padding: 20px;

  font-weight: bold;
}

.recommendation-content .options {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  flex: 1;
}

.recommendation-content .options .option {
  display: flex;
  align-items: center;

  width: 100%;

  border-radius: 8px;

  padding: 20px;

  box-shadow: 0 0 10px var(--button-shadow-color);
  color: var(--button-text-color);

  opacity: 0.7;

  cursor: pointer;
}

.recommendation-content .options .option.selected {
  opacity: 1;
  box-shadow: 0 0 5px var(--color-primary);
}

.recommendation-content .explanation {
  text-align: center;
  width: 85%;
  margin-top: 30px;
}

@media (max-width: 750px) {
  .recommendation-content h1 {
    font-size: 24px;
    text-align: center;
  }

  .recommendation-content .explanation {
    margin-top: 1rem;
    max-height: 10rem;
    text-indent: 3ch;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
  }
}
