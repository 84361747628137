.register-fabricator-page {
  width: 100vw;
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
}

.register-fabricator-page {
  display: flex;
  align-items: center;
}

.register-fabricator-page header button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: transparent;
  height: 3.5rem;
  color: #fff;
}

.register-fabricator-page strong {
  font-size: 1.3rem;
  font-family: "Montserrat", sans-serif;
}

.register-fabricator-page .logout-button {
  align-items: center;
  width: 3.5rem;
  border-radius: 50%;
  margin-left: 1.5rem;
}

.register-fabricator-page .logout-button:hover {
  background: var(--color-primary-light);
  color: var(--color-secundary);
}

.register-fabricator-page main {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  flex: 1;

  background-color: #fff;

  overflow: auto;

  padding: 1rem;
}

.register-fabricator-page main .form-register {
  flex: 1;

  border-radius: 3px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.register-fabricator-page main .title-form {
  margin-bottom: 3rem;
}

@media (max-width: 800px) {
  .register-fabricator-page main {
    flex-direction: column;
  }
}
