.header-questionary-component {
  width: 100%;

  display: flex;
  justify-content: center;

  z-index: 10;

  padding: 10px 0 15px 0;
}

.header-questionary-component .content {
  width: 100%;

  background-color: var(--background-main);
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 20px;

  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  overflow: hidden;
}

.header-questionary-component .content h1 {
  text-align: center;
}

.header-questionary-component .content button {
  background-color: transparent;
  color: var(--button-text-color);

  font-size: 40px;
  height: 50px;
  width: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: 300ms;
}

.back-text {
  color: var(--button-text-color);
  font-size: 20px;
  position: absolute;
  left: 4rem;
}

.next-text {
  color: var(--button-text-color);
  font-size: 20px;
  position: absolute;
  right: 4rem;
}

.header-questionary-component .content button:not(:disabled):hover {
  font-size: 50px;
}

.header-questionary-component .content button:disabled {
  opacity: 0.3;

  cursor: initial;
}

@media (max-width: 650px) {
  .header-questionary-component .content h1 {
    font-size: 25px;
  }
}
