.range-bar {
  height: 10px;
  width: 100%;
  background: var(--color-primary-opacity);
  margin-top: 15px;
  margin-bottom: 10px;
}

.range-button {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background: var(--color-primary-light);
  color: var(--color-secundary);
  box-shadow: 0 0 10px var(--background-main);

  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: center;
}

.range-mark {
  padding: 3px;

  border-radius: 3px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.range-mark .number {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--color-secundary);
  color: var(--color-primary-light);
  border: 1px solid var(--color-primary-opacity);

  font-size: 12px;
  font-weight: bold;

  aspect-ratio: 1;

  padding: 1px;

  border-radius: 50%;
}
