.button-quit-questionary-component {
  position: fixed;
  bottom: 0;
  right: 2px;

  background-color: transparent;
  color: var(--color-primary);

  display: flex;
}

.button-quit-questionary-component p {
  opacity: 0;
  transition: 400ms;
  font-size: 1rem;
}

.button-quit-questionary-component .icon {
  font-size: 1.3rem;
  margin-left: 1rem;
}

.button-quit-questionary-component:hover {
  bottom: 0.1rem;
}

.button-quit-questionary-component:hover p {
  opacity: 1;
}
