.content-questionary {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: calc(100vh - 100px);

  box-shadow: 0 0 10px 0 var(--button-shadow-color);
  border-radius: 8px;
}

.content-questionary .action-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
}

.content-questionary .image-container {
  height: 100%;
  max-width: 50%;

  border-radius: 0 8px 8px 0;

  overflow: hidden;
}

.content-questionary .image-container img {
  height: 100%;
}

@media (max-width: 900px) {
  .content-questionary .image-container {
    display: none;
  }
}
