.search-input-component {
  display: flex;
  align-items: center;
}

.search-input-component input {
  width: 12rem;
  height: 2rem;
  border-radius: 0.3rem;
  padding: 0 0.5rem;
  background: #fff;
  border: 0.05rem solid #999;
}

.search-input-component input:focus,
select:focus {
  border: 0.1rem solid var(--color-primary);
}

.search-input-component .search-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 0.3rem;
  background: var(--color-primary);
  border: 0;
  padding: 0;
  color: #fff;
  margin-left: 1rem;
}

.search-input-component .search-button:hover {
  background: var(--color-primary-light);
  color: var(--color-third);
}
