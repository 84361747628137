.question-teste  {
  display: flex;
  width: 100%;
  flex: 1;
  padding: 0 3rem 0 3rem;
  flex-direction: column;
  align-items: center;
}

h1 {
  text-align: center;
  margin: 5px;
}

.question-teste h4 {
  margin-top: 5px;
}

/* @media (max-width: 1170px) {
  .question-teste {
    flex-direction: row;
  }

  .question-teste h4 {
    width: 50%;
    font-size: 0.9rem;
    padding-right: 1rem;
  }
} */

/* @media (max-width: 600px) {
  .question-teste {
    flex-direction: column;
    width: 100%;
    padding: 0 1rem 0 1rem;
  }

  .question-teste h4 {
    width: 90%;
    margin-bottom: 0.5rem;
  }
} */
