.antireflection-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.antireflection-content .recommendation {
  color: var(--color-third);
  font-weight: bold;
}

.antireflection-content .header-options {
  width: 100%;

  padding: 0 1rem;

  display: flex;

  margin-bottom: 1rem;
}

.antireflection-content .header-options .lens-name {
  width: 10rem;
  min-width: 10rem;
  font-size: 0.7rem;
  margin-right: 1rem;
}

.antireflection-content .header-options p {
  font-size: 0.6rem;
  font-weight: bold;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.antireflection-content .options {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.3rem;
}

.antireflection-content .options .option {
  display: flex;
  align-items: center;
  width: 100%;

  padding: 1rem;
  border-radius: 3px;
  box-shadow: 0 4px 2 -2px var(--color-primary);
  color: var(--color-primary-light);
  opacity: 0.8;
}

.antireflection-content .options .option:hover {
  filter: brightness(0.9);
}

.antireflection-content .options .option.selected {
  background-color: var(--color-primary-opacity);
}

.antireflection-content .option .box-lens {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 10rem;
  background: #fff;
  color: var(--color-primary);
  border: 1px solid var(--color-primary-light);
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin-right: 1rem;
}

.antireflection-content .option .box-lens img {
  width: 100%;
}

.antireflection-content .option .box-lens .description {
  font-size: 0.6rem;
  margin-top: 0.7rem;
  width: 100%;
}

.antireflection-content .options .option p {
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: 1rem;
  font-weight: bold;
  flex: 1;

  gap: 5px;
}

.antireflection-content .options .option .residual-color {
  width: 2rem;
  height: 2rem;
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid var(--color-primary);
}

.antireflection-content .not-ar {
  width: 15rem;
  height: 2.5rem;
  border-radius: 0.3rem;
  background-color: transparent;
  color: #333;
  text-decoration: none;
  margin-top: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1200px) {
  .antireflection-content .header-options .digital-brand,
  .antireflection-content .options .option .digital-brand {
    display: none;
  }

  .antireflection-content .header-options p {
    font-size: 8px;
  }
  .antireflection-content .options .option p {
    font-size: 10px;
  }
}

@media (max-width: 1100px) {
  .antireflection-content .header-options .scarf,
  .antireflection-content .options .option .scarf {
    display: none;
  }
}

@media (max-width: 1000px) {
  .antireflection-content .options {
    display: flex;
    flex-direction: column;
  }

  .antireflection-content .header-options .lens-name,
  .antireflection-content .options .option .box-lens {
    width: 7rem;
    min-width: 7rem;
  }

  .antireflection-content .options .option .box-lens .description {
    font-size: 7px;
  }

  .antireflection-content .options .option .residual-color {
    width: 1.5rem;
    height: 1.5rem;
  }
}

@media (max-width: 900px) {
  .antireflection-content .header-options .uv-protect,
  .antireflection-content .options .option .uv-protect {
    display: none;
  }

  .antireflection-content .header-options p {
    font-size: 6px;
  }
  .antireflection-content .options .option p {
    font-size: 8px;
  }
}

@media (max-width: 700px) {
  .antireflection-content .header-options .haze-resistence,
  .antireflection-content .options .option .haze-resistence {
    display: none;
  }
}

@media (max-width: 650px) {
  .antireflection-content h1 {
    font-size: 1.5rem;
    text-align: center;
  }

  .antireflection-content .modal-video div {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .antireflection-content .options .option .range-score {
    display: none;
  }
}

@media (max-width: 550px) {
  .antireflection-content .header-options .lens-name,
  .antireflection-content .options .option .box-lens {
    width: 5rem;
    min-width: 5rem;
  }

  .antireflection-content .options .option .box-lens .description {
    font-size: 5px;
  }
}

@media (max-width: 500px) {
  .antireflection-content .header-options {
    display: none;
  }

  .antireflection-content .options .option > p {
    display: none;
  }

  .antireflection-content .options .option .box-lens {
    width: 100%;
  }

  .antireflection-content .options .option .box-lens .description {
    font-size: 10px;
  }
}
