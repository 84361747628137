.form-radio-check {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.form-radio-check .radio-options {
  display: grid;
  grid-template-columns: auto auto;
  gap: 5px;
}

.form-radio-check .radio-options .button-radio {
  width: 15px;
  height: 15px;

  margin-right: 5px;

  border: 1px solid var(--color-primary);
  color: var(--color-primary);
}
