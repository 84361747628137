.modal-quit-questionary-component {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--color-primary-opacity);

  z-index: 20;
}

.modal-quit-questionary-component .modal-content {
  display: flex;
  flex-direction: column;

  background-color: #fff;

  padding: 16px;
  border-radius: 8px;
}

.modal-quit-questionary-component .modal-title {
  margin-bottom: 1rem;
}

.modal-quit-questionary-component .modal-actions {
  display: flex;
  justify-content: flex-end;
}

.modal-quit-questionary-component .modal-actions button {
  padding: 0 8px;
  margin-top: 10px;

  color: var(--color-primary);

  font-size: 1rem;
}

.modal-quit-questionary-component .modal-actions button:first-child {
  font-weight: bold;
}
