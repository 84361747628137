.list-component {
  flex: 1;
  width: 100%;
  height: 100%;

  padding: 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  overflow: auto;
}

.list-component .filters-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  gap: 10px;
}

.list-component header {
  display: flex;

  padding: 1rem;

  width: 100%;
}

.list-component header .list-titles {
  flex: 1;
  font-weight: bold;
  font-size: 20px;
  display: flex;
}

.list-component .button-sort {
  height: 2rem;
  flex: 1;
  color: black;
}

.list-component article {
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 0 5px #aaa;
  padding: 1rem;

  display: flex;
  align-items: center;
}

.list-component article + article {
  margin-top: 1rem;
}

.list-component article .attribute {
  flex: 1;
  overflow: hidden;
  padding: 0 5px;
}

.list-component article .icon-desc + .icon-desc {
  margin-left: 0.5rem;
}

.list-component article :last-child {
  text-align: end;
}

.list-component article :first-child {
  text-align: start;
}

.list-component article > div {
  margin-left: 1rem;
}

.list-component article .actions {
  cursor: pointer;
  transition: 100ms;

  filter: brightness(0);
}

.list-component article .actions:hover {
  filter: opacity(0.85);
  filter: brightness(1);
}

.list-component article .actions + .actions {
  margin-left: 1rem;
}

@media (max-width: 800px) {
  .list-component {
    border-top: 1px solid #aaa;
    margin-top: 1rem;
    width: 100%;
  }
}

.modal-presentation-component {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-primary-opacity);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
}

.modal-presentation-component main {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  min-width: 300px;
  height: 80%;
  min-height: 480px;
  background: var(--background-main);
}

.modal-presentation-component main div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75%;
  height: 100%;
  justify-content: space-around;
}

.modal-presentation-component main .content .logo {
  width: 150px;
}

.modal-presentation-component main .content .title {
  text-align: start;
  color: var(--color-primary);
  width: 80%;
  font-size: 1.6rem;
  margin-bottom: 2rem;
  text-align: justify;
}

.modal-presentation-component main .content .actions {
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-presentation-component main .content .actions .start-questionary {
  width: 40%;
  height: 3rem;
  background: var(--color-primary);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  border-radius: 0.3rem;
  margin-bottom: 0.5rem;
}

.modal-presentation-component main .content .actions .start-questionary:hover {
  filter: opacity(0.9);
  color: var(--color-third);
}

.modal-presentation-component main .content .actions .start-questionary .button-icon {
  margin-left: 1rem;
}

.modal-presentation-component main .content .actions .quit-questionary {
  width: 40%;
  height: 3rem;
  background: transparent;
  color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  border-radius: 0.3rem;
}

.modal-presentation-component main .content .actions .quit-questionary:hover {
  color: var(--color-primary-light);
}

@media (max-width: 1170px) {
  .modal-presentation-component main {
    display: flex;
    flex-direction: column;
  }
  .modal-presentation-component main .content .title {
    font-size: 1rem;
  }
  .modal-presentation-component main div {
    width: 100%;
  }

  .modal-presentation-component main .content .actions .start-questionary,
  .modal-presentation-component main .content .actions .quit-questionary {
    width: 50%;
    max-width: 15rem;
    min-width: 5rem;
    height: 2rem;
    font-size: 1rem;
  }
}

@media (max-width: 450px) {
  .modal-presentation-component main .content .logo {
    width: 100px;
  }

  .modal-presentation-component main .content .title {
    font-size: 0.9rem;
  }
}
