.button-radio-select-component {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  min-width: 8rem;
  height: 40px;
  min-height: 30px;

  border-radius: 8px;

  background-color: transparent;
  box-shadow: 0 0 10px var(--button-shadow-color);
  color: var(--button-text-color);

  margin: 0 0.3rem 1rem 0.3rem;

  transition: 200ms;
}

.button-radio-select-component:hover {
  background-color: var(--color-primary-opacity);
}

.button-radio-select-component.selected {
  background-color: var(--color-primary-light);
  color: var(--color-secundary);
}
