.edit-page {
  width: 100vw;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logout-button {
  align-items: center;
  width: 2.5rem;
  border-radius: 50%;
  margin-left: 1.5rem;
}

.logout-button:hover {
  background: var(--color-primary-light);
  color: var(--color-secundary);
}

.edit-page main {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  height: calc(100vh - 5rem);
  min-height: 30rem;
  background-color: #fff;
}

.edit-page main form {
  display: grid;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;
  grid-template-areas:
    'name name name name gender gender'
    'email email email phone phone phone'
    'cpf cpf cpf birth birth birth'
    'city city city city city uf'
    'buttons buttons buttons buttons buttons buttons';
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.edit-page main form .name {
  grid-area: name;
}

.edit-page main form .cpf {
  grid-area: cpf;
}

.edit-page main form .email {
  grid-area: email;
}

.edit-page main form .phone {
  grid-area: phone;
}

.edit-page main form .gender {
  grid-area: gender;
}

.edit-page main form .birth {
  grid-area: birth;
}

.edit-page main form .city {
  grid-area: city;
}

.edit-page main form .uf {
  grid-area: uf;
}

.edit-page main form .input-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.edit-page main form .input-box input,
.edit-page main form .input-box select {
  width: 100%;
  height: 2.5rem;
  border-radius: 0.3rem;
  padding-left: 1rem;
  border: 0.05rem solid #999;
}

.edit-page main form .input-box input:focus,
.edit-page main form .input-box select:focus {
  border: 0.1rem solid var(--color-primary);
}

.edit-page main form .input-box .input-error {
  border: 0.1rem solid #9e0404;
}

.edit-page main form .actions-button {
  grid-area: buttons;
  display: flex;
}

.edit-page main form .button-confirm {
  width: 50%;
  height: 2.5rem;
  border-radius: 0.3rem;
  background: var(--color-primary);
  color: #fff;
  margin: 3rem 1rem 0rem 1rem;
}

.edit-page main form .button-confirm:hover {
  background: var(--color-primary-light);
}

.edit-page main form .button-cancel {
  width: 50%;
  height: 2.5rem;
  border-radius: 0.3rem;
  background: #fff;
  color: var(--color-primary);
  margin: 3rem 1rem 0rem 1rem;
}

@media (max-width: 800px) {
  .edit-page main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: fit-content;
  }

  .edit-page main,
  .edit-page main .new-response-box {
    width: 100%;
    height: auto;
  }

  .edit-page main form {
    display: flex;
    flex-direction: column;
  }
}