.Monitoring {
  width: 100vw;
  background: #fff;
  display: flex;
  flex-direction: column;

  overflow: auto;
}

.Monitoring main {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: calc(100vh - 5rem);
  min-height: 30rem;
  background-color: #fff;
}

.Monitoring .separator {
  width: 0.1rem;
  height: 90%;
  background: #999;
}

.Monitoring main .monitor,
.Monitoring main .shopList {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 100%;
  padding: 2rem;
}

.Monitoring main .shopList .content {
  overflow-y: auto;
  width: 100%;
  height: 100%;
}

.MonitorItem {
  max-width: 100%;
  align-items: flex-start;
}

.MonitorItem .consultants {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  
  overflow-x: auto;
}

.MonitorItem .consultants div {
  font-size: 18px;
}