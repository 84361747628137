.box-select {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.form-select-component {
  width: 15rem;
  max-width: 100%;
  height: 2.5rem;
  min-height: 2.5rem;
  border-radius: 0.3rem;
  border: 1px solid #999;
  background: #fff;
  margin-bottom: 0.5rem;
}

.form-select-component:focus {
  border: 0.1rem solid var(--color-primary);
}

.form-select-component:disabled {
  border: 0.1rem solid #9e0404;
}

.form-select-component option {
  font-size: 0.9rem;
  width: 100%;
}

strong.input-required {
  font-size: 16px !important;
  margin-left: 5px;
  color: #9e0404;
}
