.modal-video-component {
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;

  background-color: var(--color-primary-opacity);

  z-index: 10;
}

.modal-video-component .modal-content {
  width: 800px;
  max-width: 95%;

  display: flex;
  justify-content: center;

  border-radius: 8px;

  position: relative;
}

.modal-video-component .modal-content .button-close {
  position: absolute;

  background-color: var(--color-primary-opacity);
  color: var(--color-secundary);

  display: flex;
  align-items: center;
  justify-content: center;

  width: 30px;
  height: 30px;

  top: 5px;
  right: 5px;

  transition: 150ms;
}

.modal-video-component .modal-content .button-close:hover {
  opacity: 0.85;
}

.modal-video-component .modal-content img {
  width: 100%;
  border-radius: 8px;
}
