.home-page {
  width: 100vw;
  background: #fff;
  display: flex;
  flex-direction: column;

  overflow: auto;
}

.home-page .actions-header {
  display: flex;
  align-items: center;
  position: relative;
}

.home-page .actions-header button,
.home-page .actions-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: transparent;
  height: 3.5rem;
  color: #fff;
  width: 15rem;
}

.home-page .actions-header .profile-data {
  cursor: pointer;
}

.home-page .actions-header .profile-data strong {
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
}

.home-page .actions-header .profile-data strong.options {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.home-page .actions-header .profile-data label {
  font-size: 0.8rem;
  font-weight: lighter;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
}

.home-page .actions-header .logout-button {
  align-items: center;
  width: 3.5rem;
  border-radius: 50%;
  margin-left: 1.5rem;
}

.home-page .actions-header .logout-button:hover {
  background: var(--color-primary-light);
  color: var(--color-third);
}

.home-page main {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: calc(100vh - 5rem);
  min-height: 30rem;
  background-color: #fff;
}

.home-page main h3 {
  margin-bottom: 2rem;
}

.home-page main label {
  font-size: 1rem;
  font-weight: 100;
  margin-bottom: 0.3rem;
}

.home-page main .separator {
  width: 0.1rem;
  height: 90%;
  background: #999;
}

.home-page main .new-response-box,
.home-page main .register-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 100%;
  padding: 2rem;
}

.home-page main .new-response-box form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.home-page main .new-response-box form hr {
  width: 20rem;
  height: 1rem;
  color: #999;
}

.home-page main .new-response-box form .search-bar label {
  font-weight: 400;
}

.home-page main .new-response-box form .client-searched {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.home-page main .new-response-box form .client-searched .actions-client {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.home-page main .new-response-box form .client-searched div strong {
  display: flex;
  justify-content: space-between;
  width: 15rem;
}

.home-page main .new-response-box form .client-searched .edit-data-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 2rem;
  border-radius: 0.3rem;
  background: #fff;
  border: 0;
  padding: 0;
  color: var(--color-primary);
  margin-top: 1rem;
}

.home-page main .new-response-box form .client-searched .new-response-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 2rem;
  border-radius: 0.3rem;
  background: var(--color-primary);
  border: 0;
  padding: 0;
  color: #fff;
  margin-top: 1rem;
}

.home-page
  main
  .new-response-box
  form
  .client-searched
  .new-response-button:hover {
  background: var(--color-primary-light);
  color: var(--color-third);
}

/* Register box */

.home-page main .register-box form {
  display: grid;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;
  grid-template-areas:
    "name name name name gender gender"
    "email email email phone phone phone"
    "cpf cpf cpf birth birth birth"
    "uf uf city city city city"
    "button button button button button button";
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.home-page main .register-box form .name {
  grid-area: name;
}

.home-page main .register-box form .cpf {
  grid-area: cpf;
}

.home-page main .register-box form .email {
  grid-area: email;
}

.home-page main .register-box form .phone {
  grid-area: phone;
}

.home-page main .register-box form .gender {
  grid-area: gender;
}

.home-page main .register-box form .birth {
  grid-area: birth;
}

.home-page main .register-box form .city {
  grid-area: city;
}

.home-page main .register-box form .uf {
  grid-area: uf;
}

.home-page main .register-box form .input-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.home-page main .register-box form .input-box input,
.home-page main .register-box form .input-box select {
  width: 100%;
  height: 2.5rem;
  border-radius: 0.3rem;
  padding-left: 0.5rem;
  border: 0.05rem solid #999;
  background: #fff;
}

.home-page main .register-box form .input-box input:focus,
.home-page main .register-box form .input-box select:focus {
  border: 0.1rem solid var(--color-primary);
}

.home-page main .register-box form .input-box .input-error {
  border: 0.1rem solid #9e0404;
}

.home-page main .register-box form .button-register {
  grid-area: button;
  height: 2.5rem;
  border-radius: 0.3rem;
  background: var(--color-primary);
  color: #fff;
  margin: 3rem 1rem 0rem 1rem;
  font-size: 1rem;
}

.home-page main .register-box form .button-register:hover {
  background: var(--color-primary-light);
  color: var(--color-third);
  font-weight: bold;
}

@media (max-width: 1000px) {
  .home-page main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: fit-content;
  }

  .home-page main .separator {
    width: 90%;
    height: 0.1rem;
  }

  .home-page main .register-box,
  .home-page main .new-response-box {
    width: 100%;
    height: auto;
  }

  .home-page main .register-box form {
    display: flex;
    flex-direction: column;
  }
}
