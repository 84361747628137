.register-lenses-page {
  width: 100vw;
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.register-lenses-page {
  display: flex;
  align-items: center;
}

.register-lenses-page header button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: transparent;
  height: 3.5rem;
  color: #fff;
}

.register-lenses-page strong {
  font-size: 1.3rem;
  font-family: "Montserrat", sans-serif;
}

.register-lenses-page .logout-button {
  align-items: center;
  width: 3.5rem;
  border-radius: 50%;
  margin-left: 1.5rem;
}

.register-lenses-page .logout-button:hover {
  background: var(--color-primary-light);
  color: var(--color-secundary);
}

.register-lenses-page main {
  display: flex;
  justify-content: center;

  width: 100%;
  height: calc(100vh - 3.5rem);

  background-color: #fff;

  padding: 1rem;
}

.register-lenses-page main .form-register {
  flex: 1;

  border-radius: 3px;

  display: flex;
  flex-direction: column;
  align-items: center;

  overflow: auto;

  margin-bottom: 1rem;
}

.register-lenses-page main .form-register .title-form {
  margin-bottom: 3rem;
}

@media (max-width: 800px) {
  .register-lenses-page main {
    flex-direction: column;
  }
}
