.register-family-page {
    width: 100vw;
    height: 100vh;
    background: #fff;
    display: flex;
    flex-direction: column;
  }
  
  .register-family-page {
    display: flex;
    align-items: center;
  }
  
  .register-family-page header button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: transparent;
    height: 3.5rem;
    color: #fff;
  }
  
  .register-family-page strong {
    font-size: 1.3rem;
    font-family: "Montserrat", sans-serif;
  }
  
  .register-family-page .logout-button {
    align-items: center;
    width: 3.5rem;
    border-radius: 50%;
    margin-left: 1.5rem;
  }
  
  .register-family-page .logout-button:hover {
    background: var(--color-primary-light);
    color: var(--color-secundary);
  }
  
  .register-family-page main {
    display: flex;
    align-items: center;
    justify-content: center;
  
    width: 100%;
    flex: 1;
  
    background-color: #fff;
  
    overflow: auto;
  
    padding: 1rem;
  }
  
  .register-family-page main .form-register {
    flex: 1;
  
    border-radius: 3px;
  
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .register-family-page main .title-form {
    margin-bottom: 3rem;
  }

  .updated-lenses {
    display: flex;
    justify-content: center;
    align-items: normal;
    margin: 0 auto;
    padding: 20px;
    width: 50%; /* Ajusta a largura de acordo com o tamanho da tela */
    max-width: 1920px; /* Limita a largura máxima em telas maiores */
    flex-direction: column; /* Empilha os elementos em coluna */
}

.form-update {
    display: flex;
    justify-content: center;
    align-items: normal;
    margin: 20px auto;
    padding: 20px;
    width: 90%; /* Ajusta a largura */
    max-width: 1920px; /* Limita a largura máxima */
    flex-direction: column; /* Empilha os elementos em coluna */
}


@media (max-width: 800px) {
    .updated-lenses, .form-update {
        width: 95%; 
        padding: 10px; 
    }
}


@media (max-width: 1920px) {
  .updated-lenses{
   margin-top: -30%;
   margin-left: 50%;
  }

  .form-update{
   margin-top: -99%;
   margin-left: 95%;
  }
}






@media (min-width: 1441px) and (max-width: 1600px) {
  .updated-lenses{
   margin-top: -25%;
   margin-left: 45%;
  }

  .form-update{
   margin-top: -75%;
   margin-left: 65%;
  }
}


@media (min-width: 1367px) and (max-width: 1440px) {
  .updated-lenses{
   margin-top: -40%;
   margin-left: 42%;
  }

  .form-update{
   margin-top: -98%;
   margin-left: 58%;
  }
}


@media (min-width: 1281px) and (max-width: 1366px) {
  .updated-lenses{
   margin-top: -27%;
   margin-left: 40%;
  }

  .form-update{
   margin-top: -64%;
   margin-left: 55%;
  }
}


@media (min-width: 801px) and (max-width: 1280px) {
  .updated-lenses{
   margin-top: -30%;
   margin-left: 38%;
  }

  .form-update{
   margin-top: -65%;
   margin-left: 55%;
  }
}


 
  
  @media (max-width: 800px) {
    .register-family-page main {
      flex-direction: column;
    }
  }
  
