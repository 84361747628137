.sun-light-content {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  flex: 1;

  box-shadow: 0 0 10px 0 var(--color-primary-opacity);
  border-radius: 0.5rem;
}

.sun-light-content .action-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
}

.sun-light-content .action-container .question-box {
  display: flex;
  width: 100%;
  flex: 1;
  padding: 0 3rem 0 3rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sun-light-content .action-container .question-box h4 {
  font-weight: normal;
  margin-bottom: 1rem;
  text-align: center;
}

.sun-light-content .action-container .question-box h4 strong {
  transition: 300ms;
}

.sun-light-content .action-container .question-box h4 strong.error {
  font-size: 1.1rem;
  color: var(--color-error);
}

.sun-light-content .action-container .question-box .options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.sun-light-content .action-container .question-box .options button {
  display: flex;
  flex: 1;
  min-width: 8rem;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  color: var(--color-primary);
  border-radius: 0.3rem;
  margin: 0 0.3rem 1rem 0.3rem;
}

.sun-light-content .action-container .question-box .options button.checked {
  background: var(--color-primary);
  color: var(--color-third);
}

.sun-light-content .action-container .question-box .options button:hover {
  background: var(--color-primary-light);
  color: var(--color-secundary);
}

@media (max-width: 1170px) {
  .sun-light-content {
    display: flex;
    flex-direction: column;
  }

  .sun-light-content .action-container,
  .sun-light-content .image-container {
    width: 100%;
  }

  .sun-light-content .action-container .question-box {
    width: 100%;
    padding: 0.2rem;
  }

  .sun-light-content .action-container .question-box .options {
    flex-direction: column;
  }
}
