.range-score {
  width: 90%;
  height: 0.5rem;

  background: transparent;

  display: flex;
  justify-content: center;
  gap: 3px;
}

.range-score .ball {
  width: 0.5rem;
  height: 100%;
  background-color: var(--color-primary);
  opacity: 0.3;
  border-radius: 5px;
}

.range-score .ball.scored {
  opacity: 1;
}
