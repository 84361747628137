.technology-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;

  width: 100%;
}

.technology-content .recommendation {
  color: var(--color-primary);
  font-weight: bold;
  margin-bottom: 16px;
}

.technology-content .options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 500px));
  grid-template-rows: auto;
  gap: 16px;
  justify-items: center;

  padding: 10px 15px;

  flex: 1;

  overflow: auto;

  max-width: 100%;
}

.technology-content .options .option {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 160px;
  max-height: 200px;

  padding: 1rem;
  border-radius: 0.3rem;

  background-color: transparent;
  box-shadow: 0 0 10px var(--color-primary-opacity);
  color: var(--color-primary);
}

.technology-content .options .option.selected {
  background-color: var(--color-primary-opacity);
}

.technology-content .options .box-lens {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex: 1;
  height: 100%;

  padding: 0.5rem;

  background: #ffffff30;
  border-radius: 0.5rem;
}

.technology-content .options .box-lens img {
  width: 100%;
}

.technology-content .options .box-lens h4 {
  color: #D3D3D3;
}

.technology-content .options .option .title {
  font-weight: bold;
}

.technology-content .options .option .explanation {
  flex: 1;
  font-size: 0.8rem;
  font-weight: normal;
  padding: 0 1.5rem;
  text-align: initial;
}

.technology-content .options .option .info {
  width: 20px;
  height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  color: var(--color-primary);
}

.technology-content .options .option .info .icon {
  width: 100%;
  height: 100%;
}

.technology-content .option.not {
  background-color: transparent;
  color: #333;
  cursor: pointer;
  margin-top: 0.5rem;
}

.technology-content p {
  text-align: center;
}

.technology-content .extra-info {
  width: 100%;

  margin-top: 1rem;
}

.technology-content .modal.hidden,
.technology-content .modal {
  display: none;
}

.technology-content .modal-video {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #00000077;
}

.technology-content .modal-video.hidden {
  display: none;
}

.technology-content .modal-video div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: #fff;
  border-radius: 0.5rem;
  width: 90%;
  height: 90%;
  padding: 1.5rem;
}

.technology-content .modal-video div .video {
  width: 100%;
  height: 100%;
}

.technology-content .modal-video div button {
  color: var(--color-primary);
  font-weight: bold;
  margin: 1rem 0;
}

@media (max-width: 750px) {
  .technology-content .options .option {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: fit-content;
  }

  .technology-content .options .option {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: auto;
    height: fit-content;
    max-height: fit-content;
  }

  .technology-content .options .option .box-lens {
    width: 100%;
    min-height: 7rem;
    height: fit-content;
    margin-bottom: 0.7rem;
  }

  .technology-content .options .option .box-lens img {
    width: 100%;
  }

  .technology-content .options .option .explanation {
    padding: 0;
  }
}