.register-level-page {
  width: 100vw;
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
}

.register-level-page {
  display: flex;
  align-items: center;
}

.register-level-page header button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: transparent;
  height: 3.5rem;
  color: #fff;
}

.register-level-page strong {
  font-size: 1.3rem;
  font-family: "Montserrat", sans-serif;
}

.register-level-page .logout-button {
  align-items: center;
  width: 3.5rem;
  border-radius: 50%;
  margin-left: 1.5rem;
}

.register-level-page .logout-button:hover {
  background: var(--color-primary-light);
  color: var(--color-secundary);
}

.register-level-page main {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  flex: 1;

  background-color: #fff;

  overflow: auto;

  padding: 1rem;
}

.register-level-page main .form-register {
  width: 15rem;
  max-width: 100%;

  border-radius: 3px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.register-level-page main .form-register .level-box {
  width: 100%;
  display: flex;
  justify-content: center;
}

.register-level-page main .form-register .level-box button {
  width: 2rem;
  height: 2rem;

  background-color: var(--color-primary);
  color: var(--color-secundary);

  border-radius: 3px;
}

.register-level-page main .form-register .level-box label {
  flex: 1;
  text-align: center;
}

.register-level-page main .title-form {
  margin-bottom: 3rem;
}

@media (max-width: 800px) {
  .register-level-page main {
    flex-direction: column;
  }
}
