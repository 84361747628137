.refraction-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.refraction-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  width: 90%;
  height: 90%;
  min-height: 30rem;

  background: var(--background-main);

  border-radius: 0.5rem;
  padding: 2rem;

  transition: 700ms;
  position: relative;
}

.refraction-content h3 {
  text-align: center;
}

.refraction-content .observation {
  font-size: 12px;
  font-weight: bold;
  color: #333;
}

.refraction-content .options {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  gap: 10px;

  overflow: auto;

  width: 100%;

  flex: 1;

  padding: 10px 0;

  background: transparent;
}

.refraction-content .options .option {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 200px;
  max-width: 100%;

  max-height: 450px;

  gap: 20px;

  padding-bottom: 10px;

  border-radius: 5px;

  background-color: var(--background-main);
  color: var(--color-primary-light);

  box-shadow: 0 0 10px var(--color-primary-opacity);

  font-weight: bold;
  cursor: pointer;
}

.refraction-content .options .option:hover {
  opacity: 0.9;
}

.refraction-content .options .option.selected {
  filter: brightness(0.9);
  opacity: 0.9;
}

.refraction-content .options .option .index-image {
  background-repeat: no-repeat;
  width: 100%;
}

.refraction-content .options .option div {
  display: flex;
}

.refraction-content .options .option p + p {
  margin-left: 5px;
}

.refraction-content .options .option .description {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-items: center;
  align-items: center;
  font-weight: normal;
  height: 100%;
}

.refraction-content .options .option .description .separator {
  width: 100%;
  height: 1px;
  background-color: var(--color-primary);
  opacity: 0.3;
  margin: 0.5rem 0;
}

.refraction-content .explanation {
  text-align: justify;
}

.refraction-content .modal.hidden,
.refraction-content .modal {
  display: none;
}

@media (max-width: 1020px) {
  .refraction-content .options {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }
}

@media (max-width: 750px) {
  .refraction-content {
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }

  .refraction-content h1 {
    font-size: 1.5rem;
    text-align: center;
  }

  .refraction-content .options .option {
    width: 100%;
  }

  .refraction-content .explanation {
    margin-top: 1rem;
    max-height: 10rem;
    text-indent: 3ch;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
  }

  .refraction-content .modal {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #00000077;
  }

  .refraction-content .modal div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: #fff;
    border-radius: 0.5rem;
    width: 85%;
    height: 60%;
    padding: 1.5rem;
  }

  .refraction-content .modal div p {
    text-align: justify;
  }

  .refraction-content .modal div button {
    color: var(--color-primary);
    font-weight: bold;
  }
}

@media (max-width: 500px) {
  .refraction-content .goBack {
    position: initial;
    margin-right: 1rem;
  }

  .refraction-content h1 p {
    font-size: 1.3rem;
  }

  .refraction-content .options .option {
    height: fit-content;

    font-size: 0.8rem;
  }

  .refraction-content .options .option + .option {
    margin-left: 0.2rem;
  }

  .refraction-content .options .option .description {
    font-size: 0.6rem;
  }
}
