.Container  {
  margin-top: 1rem;
  display: flex;
  width: 100%;
  height: 3rem;
  border: 0.5px solid gray;
  border-radius: 0.5rem;
  justify-content: center;
  justify-items: center;
  transition: 0.4s all;
  cursor: pointer;
}

.Container .item {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.Container:hover {
  background: #dae4e9;
  border: none;
}