.grid-birth {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.grid-birth .day {
  display: flex;
  flex: 2;
}

.grid-birth .month {
  display: flex;
  flex: 3;
}

.grid-birth .year {
  display: flex;
  flex: 4;
}

.grid-birth div + div {
  margin-left: 0.2rem;
}

.grid-birth div select {
  -webkit-appearance: none;
  background: #fff;
}
