.reports-header {
  display: flex;
  align-items: center;
  gap: 15px;
}

.reports-header button {
  background-color: transparent;
  color: var(--color-secundary);
  font-size: 25px;

  display: flex;
  align-items: center;
  justify-content: center;
}
