.form-input-image-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.form-input-image-component img {
  width: 200px;
}
