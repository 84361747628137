.explanation {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 500px;
  max-width: 100%;

  padding: 15px;
}

.explanation strong {
  font-size: 25px;
}

.explanation p {
  text-indent: 2ch;
  font-weight: normal;
  text-align: justify;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 15px;
}

.contact-lenses-button-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  width: 200px;
  height: 40px;

  border-radius: 8px;

  background-color: var(--color-primary-light);
  box-shadow: 0 0 10px var(--button-shadow-color);
  color: var(--color-secundary);

  transition: 200ms;
}

.contact-lenses-button-primary:hover {
  opacity: 0.9;
}

.contact-lenses-button-secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  width: 200px;
  height: 40px;

  margin-top: 15px;

  border-radius: 8px;

  background-color: transparent;
  color: var(--button-text-color);

  transition: 200ms;
}
