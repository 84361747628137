.consultant-report {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.consultant-report main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 90%;
  min-height: 30rem;
  background-color: #fff;
  position: relative;
  overflow: auto;
}

.consultant-report main .top-list {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 20px;

  width: 95%;
}

.consultant-report main .top-list .go-back {
  text-decoration: none;
  color: var(--color-primary);
  font-weight: bold;
  cursor: pointer;
}

.consultant-report main .top-list .go-back.center {
  width: auto;
  margin-top: 1rem;
}

.consultant-report main .top-list .box-input {
  display: flex;
  flex-direction: row;
}

.consultant-report main .top-list .box-input input {
  width: 150px;
  height: 40px;

  border: 1px solid var(--color-primary);
}

.consultant-report main .top-list .box-input button {
  height: 40px;
  width: 40px;

  border-radius: 5px;

  background-color: var(--color-primary);
  color: var(--color-secundary);

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 20px;
}

.consultant-report main .list-item-report {
  margin-top: 1rem;
  width: 95%;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 25px;
}

.consultant-report main .list-item-report div {
  background: #eee;
  border-radius: 0.3rem;
  padding: 0.5rem;
  cursor: pointer;
  transition: 200ms;

  display: flex;
  flex-direction: column;
}

.consultant-report main .list-item-report div:hover {
  opacity: 0.9;
}

.consultant-report main .list-item-report strong {
  margin-right: 0.7rem;
}

.consultant-report main .list-item-report p {
  margin-right: 1.2rem;
}

.consultant-report main .list-item-report a {
  text-decoration: none;
  color: inherit;
  font-weight: bold;
  margin-top: 20px;
}

.consultant-report main .table-report {
  margin-top: 1rem;
  width: 95%;
  border: 1px solid #aaa;
  margin-bottom: 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
}

.consultant-report main .table-report .button-close {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: #fff;
  color: #333;
  margin: 0.5rem;
  padding: 0.1rem;
  cursor: pointer;
  position: absolute;
  top: 0.3rem;
  right: 0.5rem;
}

.consultant-report main .table-report .button-close:hover {
  color: #555;
}

.consultant-report main .table-report h3,
.consultant-report main .table-report h2,
.consultant-report main .table-report h5 {
  width: 100%;
  border-bottom: 1px solid #aaa;
  text-align: center;
  padding: 0.5rem;
}

.consultant-report main .table-report h5 {
  padding: 0.3rem;
}

.consultant-report main .table-report .item-report {
  display: flex;
  width: 100%;
  padding: 0.3rem 0.3rem 0.3rem 1rem;
  border-bottom: 1px solid #aaa;
}

.consultant-report main .table-report .item-report strong {
  flex: 3;
  border-right: 1px solid #aaa;
  font-size: 1rem;
}

.consultant-report main .table-report .item-report p {
  flex: 7;
  padding-left: 1rem;
  font-size: 1rem;
}

.consultant-report main .print-button {
  height: 40px;
  min-height: 40px;
  width: 150px;

  margin-bottom: 30px;

  border-radius: 3px;

  background-color: var(--color-primary);
  color: var(--color-secundary);

  transition: 200ms;
}

.consultant-report main .print-button:hover {
  opacity: 0.9;
}

@media (max-width: 1000px) {
  .consultant-report main .list-item-report {
    display: flex;
    flex-direction: column-reverse;
    width: 95%;
  }
}
