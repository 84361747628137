.question-box {
  display: flex;
  width: 100%;
  flex: 1;
  padding: 0 3rem 0 3rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.question-box h4 {
  font-weight: normal;
  margin-bottom: 1rem;
  text-align: center;
}

.question-box h4 strong {
  transition: 300ms;
}

.question-box h4 strong.error {
  font-size: 1.1rem;
  color: var(--color-error);
}

.question-box .options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
