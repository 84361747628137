.button-component {
  height: 2.5rem;
  min-height: 2.5rem;
  max-width: 100%;
  width: 15rem;
  border-radius: 0.3rem;
  background: var(--color-primary);
  color: #fff;
  margin-top: 1rem;
  font-size: 1rem;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-component:hover {
  background: var(--color-primary-light);
  color: var(--color-secundary);
  font-weight: bold;
}

.button-component:disabled {
  filter: opacity(0.6);
  filter: grayscale(0.5);
  cursor: not-allowed;
}
