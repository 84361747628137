.client-page {
  width: 100vw;
  height: 100vh;
  background: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.client-page .actions-header {
  display: flex;
  align-items: center;
}

.client-page .actions-header button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: transparent;
  height: 3.5rem;
  color: #fff;
}

.client-page .actions-header strong {
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
}

.client-page .actions-header .go-back-button {
  align-items: center;
  width: 3.5rem;
  border-radius: 50%;
  margin-left: 1.5rem;
}

.client-page .actions-header .go-back-button:hover {
  background: var(--color-primary-light);
  color: var(--color-secundary);
}

.client-page main {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  flex: 1;

  background-color: #fff;

  overflow: auto;

  padding: 1rem;

  position: relative;
}

.client-page main .table-report {
  margin-top: 1rem;
  width: 95%;
  border: 1px solid #aaa;
  margin-bottom: 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
}

.client-page main .table-report .button-close {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: #fff;
  color: #333;
  margin: 0.5rem;
  padding: 0.1rem;
  cursor: pointer;
  position: absolute;
  top: 0.3rem;
  right: 0.5rem;
}

.client-page main .table-report .button-close:hover {
  color: #555;
}

.client-page main .table-report h3,
.client-page main .table-report h2,
.client-page main .table-report h5 {
  width: 100%;
  border-bottom: 1px solid #aaa;
  text-align: center;
  padding: 0.5rem;
}

.client-page main .table-report h5 {
  padding: 0.3rem;
}

.client-page main .table-report .item-report {
  display: flex;
  width: 100%;
  padding: 0.3rem 0.3rem 0.3rem 1rem;
  border-bottom: 1px solid #aaa;
}

.client-page main .table-report .item-report strong {
  flex: 3;
  border-right: 1px solid #aaa;
  font-size: 1rem;
}

.client-page main .table-report .item-report p {
  flex: 7;
  padding-left: 1rem;
  font-size: 1rem;
}
