.modal-delete-component {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #00000050;
}

.modal-delete-component .box-modal {
  background-color: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 3px;

  padding: 1rem;
}

.modal-delete-component .box-modal .box-button {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1rem;
}

.modal-delete-component .box-modal .box-button button {
  height: 2rem;
  font-size: 1rem;
  color: var(--color-primary);
}

.modal-delete-component .box-modal .box-button button[type="submit"] {
  font-weight: bold;
  margin-left: 1rem;
}
