.question-box {
  display: flex;
  width: 100%;
  flex: 1;
  padding: 0 3rem 0 3rem;
  flex-direction: column;
  align-items: center;
}

.question-box h4 {
  font-weight: normal;
  margin-bottom: 0.2rem;
  text-align: center;
}

.buttons-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

@media (max-width: 1170px) {
  .question-box {
    flex-direction: row;
  }

  .question-box h4 {
    width: 50%;
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .question-box {
    flex-direction: column;
    width: 100%;
    padding: 0 16px 0 16px;
  }

  .question-box h4 {
    width: 90%;
    margin-bottom: 8px;
  }
}
