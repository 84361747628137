.mf-table-component {
  width: 100%;

  display: flex;
  gap: 15px;
  flex: 1;
}

.mf-table-component .item-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 1;

  border-radius: 8px;

  padding: 20px;

  box-shadow: 0 0 10px var(--button-shadow-color);
  color: var(--button-text-color);

  opacity: 1;
  cursor: pointer;

  transition: 200ms;
}

.mf-table-component .description {
  text-align: start;
}

.mf-table-component .item-table * {
  cursor: pointer;
}

.mf-table-component .item-table:hover {
  filter: brightness(1.15);
}

.mf-table-component .item-table.selected {
  box-shadow: 0 0 5px var(--color-primary);
}

.mf-table-component .item-table .logo-box {
  height: 100px;
  display: flex;
  align-items: center;
}

.mf-table-component .item-table .logo-column {
  width: 200px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.mf-table-component .item-table .att-column {
  min-height: 20px;
  font-family: "Lato", sans-serif;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
}

.mf-table-component .item-table .att-column.mini {
  font-size: 12px;
}

.mf-table-component .item-table .att-column img {
  height: 120px;
  max-width: 100%;
}

.mf-table-component .item-table .att-column .box-image {
  min-height: 120px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mf-table-component .item-table .att-column.mini img {
  width: 120px;
  height: auto;
}

.mf-table-component .item-table .grid-items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 20px;
}

@media (max-width: 750px) {
  .mf-table-component {
    flex-direction: column;
  }
}
