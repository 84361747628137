.box-success-component {
  width: 100%;
  height: 40px;
  min-height: 40px;
  background-color: var(--color-success);
  color: #fff;

  border-radius: 3px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 1rem;
}
