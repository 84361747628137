.congratulations-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--background-main);
}

.congratulations-page main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  min-height: 30rem;
  border-radius: 0.5rem;
  padding: 2rem;
  transition: 700ms;
  position: relative;
  overflow: auto;
}

.congratulations-page main.transition {
  opacity: 0;
}

.congratulations-page main .header-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.congratulations-page main .header-title .title {
  margin-bottom: 1rem;
  height: 3rem;
  display: flex;
  align-items: center;
  width: 100%;
}

.congratulations-page main .header-title .logo {
  width: 100px;
}

.congratulations-page main .resume {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 1rem;
}

.congratulations-page main .resume .raio-x {
  display: flex;
  height: fit-content;
}

.congratulations-page main .resume .raio-x.not-highlights {
  justify-content: center;
}

.congratulations-page main .resume .raio-x img {
  max-height: 300px;
  min-height: 300px;
  border-radius: 0.3rem;
}

.congratulations-page main .resume .raio-x .highlights {
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 2rem;
}

.congratulations-page main .resume .raio-x .highlights h2 {
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 1rem;
}

.congratulations-page main .resume .raio-x .highlights h2 svg {
  margin-left: 0.5rem;
}

.congratulations-page main .resume .raio-x .highlights .grid-highlights {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.congratulations-page main .resume .raio-x .highlights .grid-highlights > div {
  display: flex;
  align-items: center;
  height: max-content;
}

.congratulations-page main .resume .raio-x .highlights .grid-highlights .item {
  background-color: var(--color-primary);
  color: var(--color-secundary);
  border-radius: 5px;
  padding: 1rem;
  height: 7rem;
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  z-index: 3;
}

.congratulations-page
  main
  .resume
  .raio-x
  .highlights
  .grid-highlights
  .item
  svg {
  font-size: 2rem;
}

.congratulations-page
  main
  .resume
  .raio-x
  .highlights
  .grid-highlights
  .item
  p {
  margin-top: 0.8rem;
  text-align: center;
}

.congratulations-page
  main
  .resume
  .raio-x
  .highlights
  .grid-highlights
  .explanation {
  flex: 2;
  font-size: 14px;

  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-primary-light);
  color: var(--color-secundary);
  padding: 0.7rem 0.7rem 0.7rem 2rem;
  border-radius: 5px;
  margin-left: -1.5rem;

  min-height: 6rem;

  text-align: justify;
  text-indent: 2ch;
}

.congratulations-page main .resume .infos > div {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
}

.congratulations-page main .resume .big-separator {
  width: 100%;
  height: 1px;
  margin: 4rem 0;
  opacity: 0.8;
  color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
}

.congratulations-page main .resume .infos {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.congratulations-page main .resume .separator {
  width: 0.01rem;
  height: 100%;
  background-color: var(--color-primary);
  opacity: 0.5;
}

.congratulations-page main .resume h2 {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.congratulations-page main .resume .content {
  margin-bottom: 0.5rem;
  color: var(--color-primary-light);
  font-size: 0.9rem;
}

.congratulations-page main .resume .infos .personal-info .grid-prescription {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  gap: 2px;
  background-color: #000;

  padding: 2px;
  margin-right: 1rem;
}

.congratulations-page
  main
  .resume
  .infos
  .personal-info
  .grid-prescription
  span {
  height: 2.5rem;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.congratulations-page
  main
  .resume
  .infos
  .personal-info
  .grid-prescription
  .header {
  font-weight: bold;
  background-color: #bbc;
}

.congratulations-page main .action-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;

  margin-top: 20px;
}

.congratulations-page main .action-buttons button {
  width: 15rem;
  min-width: 15rem;
  height: 3rem;
  min-height: 3rem;
  border-radius: 5px;

  font-size: 16px;

  color: var(--color-primary);
  background-color: #fff;

  transition: 200ms;
}

.congratulations-page main .action-buttons button:not(:disabled):hover {
  opacity: 0.9;
}

.congratulations-page main .action-buttons button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.congratulations-page main .action-buttons .button-finish {
  background: var(--color-primary);
  color: #fff;
}

@media (max-width: 1100px) {
  .congratulations-page main .resume .raio-x .highlights {
    height: 100%;
  }

  .congratulations-page main .resume .raio-x .highlights h2 {
    font-size: 1.4rem;
  }
}

@media (max-width: 900px) {
  .congratulations-page main .resume .raio-x .highlights .grid-highlights {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .congratulations-page
    main
    .resume
    .raio-x
    .highlights
    .grid-highlights
    .item
    + div {
    margin-top: 0.5rem;
  }
}

@media (max-width: 1000px) {
  .congratulations-page main {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    overflow: auto;
  }

  .congratulations-page main h1 {
    font-size: 1.5rem;
    text-align: center;
  }

  .congratulations-page main .resume {
    flex-direction: column;
  }

  .congratulations-page main .resume .raio-x {
    padding: 0;
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  .congratulations-page main .resume .raio-x .highlights {
    width: 100%;
    margin: 0;
    margin-top: 1rem;
  }

  .congratulations-page main .resume .raio-x .highlights h2 {
    font-size: 1.2rem;
  }

  .congratulations-page main .resume .raio-x img {
    width: 300px;
    min-width: 300px;
    min-height: 300px;
    max-height: 300px;
  }

  .congratulations-page main .resume .infos {
    flex-direction: column;
  }

  .congratulations-page main .resume .infos div {
    padding: 1rem 0;
  }

  .congratulations-page main .resume .separator {
    width: 100%;
    height: 0.05rem;
  }
}

@media (max-width: 500px) {
  .congratulations-page main .goBack {
    position: initial;
    margin-right: 1rem;
  }

  .congratulations-page main .highlights .grid-highlights > div {
    flex-direction: column;
  }

  .congratulations-page main .highlights .grid-highlights .item {
    margin-bottom: -2rem;
  }

  .congratulations-page main .highlights .grid-highlights .explanation {
    padding: 2rem 1rem 1rem 1rem !important;
    margin-left: 0 !important;
  }
}

@media (max-width: 350px) {
  .congratulations-page main .header-title {
    flex-direction: column;
  }
}
